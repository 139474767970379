<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2024-07-10 18:08:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-07-11 20:53:47
-->
<template>
	<router-view></router-view>
</template>

<script setup>
</script>
