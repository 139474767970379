<template>
	<span @click.stop="onOpen">
		<slot name="reference">
			<el-button plain type="primary" icon="plus" size="small">选择上级类型</el-button>
		</slot>
	</span>
	<slot>
		<div class="choose_items">
			<div class="item" v-for="item in model" :key="item">
				<el-avatar :size="25" :src="item.avatar_url">类</el-avatar>
				<el-text size="small">{{ FullName ? item.full_dept_name : item.name }}</el-text>
				<el-icon class="close" @click.stop="onRemoveItem(item)">
					<Close />
				</el-icon>
			</div>
		</div>
	</slot>
	<el-dialog
		title="请选择"
		v-model="visible"
		:destroy-on-close="true"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		width="800px"
		:append-to-body="true"
	>
		<el-row>
			<el-col :span="13">
				<el-input v-model="q" placeholder="请输入名称" clearable></el-input>
				<div class="body">
					<el-scrollbar>
						<el-tree
							:data="officTreeData"
							:props="{ label: 'name', children: 'child' }"
							:default-expanded-keys="expandedKeys"
							:multiple="multiple"
							:filter-node-method="filterNode"
							show-checkbox
							:check-strictly="true"
							node-key="id"
							ref="treeRef"
							:expand-on-click-node="true"
							:check-on-click-node="false"
							@check-change="handleCheckChange"
						></el-tree>
					</el-scrollbar>
				</div>
			</el-col>
			<el-col :span="11">
				<div class="header">
					<span>已选 {{ checkList.length }} 项</span>
					<el-button type="danger" link size="small" @click="onClean">清空</el-button>
				</div>
				<div class="checked body">
					<el-scrollbar v-if="checkList.length">
						<div class="dept_item" v-for="item in checkList" :key="item.id">
							<div class="flex-row item-center flex-1">
								<el-avatar
									:size="30"
									:src="item.avatar_url"
									style="margin-right: 5px; background: #79bbff"
									>类</el-avatar
								>
								<div class="dept flex-1">
									<div>{{ item.name }}</div>
									<div class="dept_name">{{ item.dept_name }}</div>
								</div>
							</div>
							<el-icon @click="onRemove(item)">
								<Close />
							</el-icon>
						</div>
					</el-scrollbar>
					<el-empty v-if="checkList.length === 0" description="暂无数据"></el-empty>
				</div>
			</el-col>
		</el-row>
		<template #footer>
			<el-button type="primary" @click="onConfirm">确定</el-button>
		</template>
	</el-dialog>
</template>

<script setup>
import { getlistType } from '@/api/topicType.js'
import { ref, onMounted, watch, nextTick } from 'vue'
const emit = defineEmits(['change'])
const model = defineModel({ type: Array, default: [] })
import { ElMessage } from 'element-plus'
const props = defineProps({
	/**
	 * 多选？
	 */
	multiple: {
		type: Boolean,
		default: false
	},
	/**
	 * 是否显示全名
	 */
	FullName: {
		type: Boolean,
		default: false
	}
})

/**
 * 打开 默认选中
 */
const visible = ref(false)
const q = ref('')
const officTreeData = ref([])
const expandedKeys = ref([])
const officList = ref([])
const treeRef = ref(null)
const checkList = ref([])
const onOpen = () => {
	visible.value = true
	console.log(
		'modemode',
		model.value,
		model.value.map((m) => m.id)
	)
	nextTick(() => {
		treeRef.value.setCheckedKeys(model.value.map((m) => m.id))
		checkList.value = treeRef.value.getCheckedNodes()
		expandedKeys.value = model.value.map((m) => m.id)
	})
}
const onConfirm = () => {
	var list = [...checkList.value]

	model.value = list
	emit('change', list)
	visible.value = false
}
const b = ref(1)
const getList = async () => {
	const res = await getlistType()
	console.log('树形数据', res)
	expandedKeys.value = []

	res.data.forEach((item) => {
		b.value = 1
		recursion(item, null, 1)
	})
	officTreeData.value = res.data
	console.log('我是加工完的', officTreeData.value)
	console.log('model.value', model.value)
	console.log('checkList', checkList.value)
	if (model.value) {
		model.value.forEach((m) => {
			let offic = officList.value.find((c) => c.id === m.id)

			if (offic) {
				m.full_dept_name = offic.full_dept_name
			}
		})
	}
}

const recursion = (item, father, i) => {
	if (father) {
		item.full_dept_name = father.full_dept_name + '/' + item.name
		item.number = i + 1
		b.value = i + 1
		if (b.value == 3) {
			item.disabled = true
		}
	} else {
		item.full_dept_name = item.name
		item.number = i
	}
	officList.value.push({
		id: item.id,
		name: item.name,
		full_dept_name: item.full_dept_name
	})

	if (item.child && item.child.length) {
		item.child.forEach((c, i) => {
			recursion(c, item, b.value)
		})
	}
}

/**
 * 筛选
 * @param value
 * @param data
 */
const filterNode = (value, data) => {
	console.log(value, data)
	if (!value) return true
	return data.name.indexOf(value) !== -1
}

const handleCheckChange = (item, checked) => {
	console.log(item, checked)
	if (!props.multiple) {
		if (checked) {
			treeRef.value.setCheckedKeys([])
			treeRef.value.setChecked(item.id, true)
		}
	}
	console.log('我被选择了')
	checkList.value = treeRef.value.getCheckedNodes()
}

const onClean = () => {
	checkList.value = []
	treeRef.value.setCheckedKeys([])
}

const onRemove = (item) => {
	treeRef.value.setChecked(item.id, false)
}

onMounted(() => {
	getList() //获取树形数据
})

watch(q, (val) => {
	treeRef.value.filter(val)
})

/**
 * 移除model的项
 */
const onRemoveItem = (item) => {
	checkList.value = checkList.value.filter((c) => c.id !== item.id)
	onConfirm()
}
</script>

<style lang="scss" scoped>
.el-col {
	height: 400px;
	border: 1px solid var(--el-border-color);
	display: flex;
	flex-direction: column;
}

.el-col:first-child {
	border-right: none;
}

.el-col .el-input {
	padding: 5px;
}

.header {
	display: flex;
	align-items: center;
	height: 40px;
	padding: 0 10px;
	justify-content: space-between;
	border-bottom: 1px solid var(--el-border-color);
}

.body {
	flex-grow: 1;
	height: 1px;
}

.checked.body .el-scrollbar {
	padding: 10px;
}

.dept_item {
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: space-between;
	padding: 5px 10px;
	border-radius: 4px;

	&:hover {
		background-color: #f5f7fa;
	}
}

.dept_item .dept_name {
	font-size: 12px;
	color: #c0c4cc;
}

// 已选择
.choose_items {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 5px;
	width: 100%;
}

.choose_items .item {
	display: flex;
	align-items: center;
	margin: 5px;
	padding: 3px 5px;
	border-radius: 15px;
	background-color: #f5f6f6;
	font-size: 14px;
	line-height: 14px;
}

.choose_items .close.el-icon {
	cursor: pointer;
	padding: 2px;
	margin-left: 2px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.choose_items .close.el-icon:hover {
	background-color: #999;
}

.choose_items .item .el-avatar {
	background-color: var(--el-color-primary);
}

.choose_items .item .el-avatar {
	background-color: var(--el-color-primary);
}

.choose_items .item .el-avatar span {
	position: absolute;
	display: flex;
	align-items: center;
}

.choose_items .el-text {
	margin-left: 3px;
}
// 为叶子节点添加dispaly:inline-block;

.sourceTree .el-tree-node .is-leaf + .el-checkbox .el-checkbox__inner {
	display: inline-block;
}

// 为不带is-leaf的节点添加display:none;

.sourceTreee .el-tree-node .el-checkbox .el-checkbox__inner {
	display: none;
}

// 为带有is-disabled的节点添加display:none;

.sourceTreee .el-tree-node .el-checkbox.is-disabled .el-checkbox__inner {
	display: none;
}
</style>
