<template>
	<el-table-column v-bind="$attrs" label="操作" fixed="right">
		<template #default="{ row }">
			<el-button
				type="primary"
				link
				icon="View"
				size="small"
				@click="emit('info', row)"
				v-if="show[0]"
			>
				详情
			</el-button>
			<el-button link icon="Edit" size="small" @click="emit('edit', row)" v-if="show[1]">
				修改
			</el-button>
			<el-popconfirm :title="delTip" @confirm="emit('delete', row)" width="200">
				<template #reference>
					<el-button
						:disabled="disabled.is && row[disabled.key] == disabled.value"
						link
						type="danger"
						icon="Delete"
						v-if="show[2]"
						size="small"
						:loading="row.deleting"
						>删除</el-button
					>
				</template>
			</el-popconfirm>
			<slot name="append" :row="row"></slot>
		</template>
	</el-table-column>
</template>

<script setup>
const props = defineProps({
	/**
	 * 删除确认弹窗的提示语
	 */
	delTip: {
		type: String,
		default: '您是否确认删除?'
	},
	/**
	 * show 数组用于控制显示哪些按钮，数组的索引对应按钮的位置，0表示详情，1表示编辑，2表示删除。默认 [1, 1, 1]
	 * 数组内容 0表示不显示，1表示显示。
	 */
	show: {
		type: Array,
		default: () => [1, 1, 1]
	},
	// 禁用按钮
	disabled: {
		type: Object,
		default: () => ({ is: false, key: '', value: 0 })
	}
})

const emit = defineEmits(['edit', 'info', 'delete'])
</script>
