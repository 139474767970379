/*
 * @Description: 菜单配置说明  父级的 path = / , icon 请查看element plus的icon文档
 * @Author: 琢磨先生
 * @Date: 2024-07-12 10:03:09
 * @LastEditors: GW 1519964028@.qq.com
 * @LastEditTime: 2024-09-24 17:17:30
 */

var menus = [
	{
		path: '/',
		icon: '',
		ignore: true, //忽略权限校验
		meta: {
			title: '开疆管理'
		},
		hideSide: false, //隐藏左侧菜单
		children: [
			// {
			// 	path: '/home',
			// 	icon: '',
			// 	ignore: true, //忽略权限校验
			// 	component: () => import('@/views/home'),
			// 	meta: {
			// 		title: '公告数据库'
			// 	}
			// },
			// {
			// 	path: '/onAndOffLine',
			// 	icon: '',
			// 	ignore: true, //忽略权限校验
			// 	component: () => import('@/views/onAndOffLine/index'),
			// 	meta: {
			// 		title: '线上线下数据'
			// 	}
			// },
			// {
			// 	path: '/visualization',
			// 	icon: '',
			// 	ignore: true, //忽略权限校验
			// 	component: () => import('@/views/onAndOffLine/visualization'),
			// 	meta: {
			// 		title: '数据可视化'
			// 	}
			// },
			// {
			// 	path: '/marketplace',
			// 	icon: '',
			// 	ignore: true, //忽略权限校验
			// 	component: () => import('@/views/marketplace/index'),
			// 	meta: {
			// 		title: '市场活动'
			// 	}
			// },
			// {
			// 	path: '/profile',
			// 	icon: '',
			// 	ignore: true, //忽略权限校验
			// 	component: () => import('@/views/profile/index'),
			// 	meta: {
			// 		title: '资料管理'
			// 	}
			// },
			// {
			// 	path: '/panel',
			// 	icon: '',
			// 	ignore: true, //忽略权限校验
			// 	hideSide: true, //隐藏左侧菜单
			// 	component: () => import('@/views/marketplace/panel'),
			// 	meta: {
			// 		title: '线索管理'
			// 	}
			// },
			{
				path: '/',
				icon: '',
				component: '',
				meta: {
					title: '学练模块'
				},
				children: [
					{
						path: '/officType',
						icon: '',
						component: () => import('@/views/learnToPractice/officType/index'),
						power: 'admin/v1/day',
						meta: {
							title: '公职类型管理'
						}
					},
					{
						path: '/PDM/dataList',
						icon: '',
						component: () => import('@/views/learnToPractice/PDM/dataList'),
						power: 'admin/v1/day',
						meta: {
							title: '资料管理'
						}
					},
					{
						path: '/PDM/dataBase',
						icon: '',
						component: () => import('@/views/learnToPractice/PDM/dataBase'),
						power: 'admin/v1/day',
						meta: {
							title: '资料库管理'
						}
					},
					{
						path: '/PDM/dataCollection',
						icon: '',
						component: () => import('@/views/learnToPractice/PDM/dataCollection'),
						power: 'admin/v1/day',
						meta: {
							title: '资料合集管理'
						}
					},
					{
						path: '/brushingQuestions/topicType',
						icon: '',
						component: () =>
							import('@/views/learnToPractice/brushingQuestions/topicType/topicType'),
						power: 'admin/v1/day',
						meta: {
							title: '题库类型'
						}
					},
					{
						path: '/brushingQuestions/questionBank',
						icon: '',
						component: () =>
							import('@/views/learnToPractice/brushingQuestions/questionBank/questionBank'),
						power: 'admin/v1/day',
						meta: {
							title: '题库管理'
						}
					},
					{
						path: '/brushingQuestions/examManagement',
						icon: '',
						component: () =>
							import('@/views/learnToPractice/brushingQuestions/examManagement/examManagement'),
						power: 'admin/v1/day',
						meta: {
							title: '试卷管理'
						}
					},
					{
						path: '/LPmangement',
						icon: '',
						component: () => import('@/views/learnToPractice/LPmangement/index'),
						power: 'admin/v1/day',
						meta: {
							title: '每日学练管理'
						}
					}
				]
			}
		]
	}
	// ...store.menu
]
// getMenu()
// // 获取菜单
// async function getMenu() {
// 	await apiMenuList().then((res) => {
// 		menus = res.data
// 	})
// }

//递归菜单处理每级菜单的url集合和菜单项id
var i = 0
menus.forEach((item) => {
	item.urls = []
	recursion(item, null, i++)
})

function recursion(item, father, i) {
	if (father) {
		item.id = `${father.id}-${i}`
	} else {
		item.id = '' + i
	}
	if (item.path && item.path != '/') {
		if (father) {
			father.urls.push(item.path)
		} else {
			item.urls = [item.path]
		}
	} else if (item.children) {
		var k = 0
		item.children.forEach((child) => {
			child.urls = []
			recursion(child, item, k++)
		})
	}
	if (father) {
		father.urls = [...father.urls, ...item.urls]
	}
}

export default menus
