import http from '@/http/axios_init2'
// 题库类型列表
export function getlistType(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/listType',
		data
	})
}

//新增题库类型类
export function addType(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/addType',
		data
	})
}

//删除题库类型
export function delType(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/delType',
		data
	})
}

//编辑题库类型
export function editType(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/editType',
		data
	})
}
