import { ref } from 'vue'

export const useBaseMixins = () => {
	const loading = ref(false) //加载数据
	const formTitle = ref('新增')
	const formVisible = ref(false) //显示编辑
	const formLoading = ref(false) //修改时表单加载数据
	const form = ref({}) //表单
	const saving = ref(false) //保存时
	const selectionNodes = ref([])
	const query = ref({
		page: 1,
		limit: 20
	}) //查询条件
	//分页表格数据
	const tableData = ref({
		data: [],
		total: 0
	})

	return {
		loading,
		tableData,
		formTitle,
		formVisible,
		formLoading,
		form,
		saving,
		selectionNodes,
		query
	}
}
export default {
	useBaseMixins: useBaseMixins
}
