<template>
	<el-dialog
		:close-on-click-modal="true"
		:close-on-press-escape="false"
		:append-to-body="true"
		:destroy-on-close="true"
		v-bind="$attrs"
	>
		<div v-loading="loading">
			<slot name="alert"></slot>
			<el-form :model="form" ref="formRef" v-bind="$attrs" v-if="mode === 'DESIGN'">
				<template v-for="item in list" :key="item">
					<el-form-item
						:label="item.label"
						:prop="item.prop"
						:style="item.col ? 'width:' + item.col : item.width ? 'width:' + item.width : ''"
					>
						<slot :name="item.prop" :prop="item" :model="form[item.prop]">
							<el-input
								v-model="form[item.prop]"
								v-if="item.type === 'input'"
								:placeholder="item.placeholder ? item.placeholder : '请输入'"
								:style="item.style"
								:disabled="item.disabled"
								clearable
							/>
							<el-input
								v-model="form[item.prop]"
								type="textarea"
								v-if="item.type === 'textarea'"
								:placeholder="item.placeholder ? item.placeholder : '请输入'"
								:style="item.style"
								:rows="item.row"
								clearable
							/>
							<el-radio-group
								v-model="form[item.prop]"
								v-if="item.type === 'radio'"
								:style="item.style"
								:disabled="item.disabled"
							>
								<el-radio
									:label="item.label"
									:value="item.value"
									v-for="item in item.options"
									:key="item.value"
									>{{ item.label }}
								</el-radio>
							</el-radio-group>
							<el-input-number
								v-model="form[item.prop]"
								v-if="item.type === 'number'"
								:placeholder="item.placeholder"
								:min="item.min"
								:max="item.max"
								:precision="item.precision"
								:style="item.style"
								clearable
							></el-input-number>
							<el-select
								v-model="form[item.prop]"
								v-if="item.type === 'select'"
								:placeholder="item.placeholder ? item.placeholder : '请选择'"
								:multiple="item['multiple']"
								:filterable="item['filterable']"
								:reserve-keyword="item['reserveKeyword']"
								:style="item.style"
								clearable
								@change="selectChange($event, item)"
							>
								<el-option
									v-for="item in item.options"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								/>
							</el-select>
							<el-cascader
								v-if="item.type === 'cascader'"
								:options="item.options"
								v-model="form[item.prop]"
								:style="item.style"
								:props="item.props"
								clearable
								filterable
							></el-cascader>
							<el-date-picker
								v-model="form[item.prop]"
								type="datetimerange"
								v-if="item.type === 'datetimerange'"
								value-format="YYYY-MM-DD HH:mm:ss"
								:start-placeholder="item.startPlaceholder"
								:end-placeholder="item.endPlaceholder"
								:style="item.style"
								clearable
							/>

							<choose-offic
								v-if="item.type === 'offic'"
								v-model="form[item.prop]"
								:multiple="item['multiple']"
								:FullName="item['fullName']"
							></choose-offic>
							<UploadImage
								v-if="item.type === 'image'"
								:multiple="item['multiple']"
								v-model="form[item.prop]"
							>
							</UploadImage>
							<div style="width: 100%">
								<UploadFile
									v-if="item.type === 'file'"
									:multiple="item['multiple']"
									v-model="form[item.prop]"
									:isLocal="item['isLocal']"
								></UploadFile>
							</div>
							<choosePic
								v-if="item.type === 'pic'"
								:picList="item.picList"
								v-model="form[item.prop]"
							></choosePic>
							<chooseDatabase
								v-if="item.type === 'base'"
								v-model="form[item.prop]"
							></chooseDatabase>
							<chooseType v-if="item.type === 'type'" v-model="form[item.prop]"></chooseType>
							<chooseTopic
								v-if="item.type === 'topic'"
								v-model="form[item.prop]"
								:multiple="item['multiple']"
								:FullName="item['fullName']"
							></chooseTopic>
							<chooseSubject
								v-if="item.type === 'subject'"
								v-model="form[item.prop]"
							></chooseSubject>

							<chooseQuestionBank
								v-if="item.type === 'question'"
								:multiple="item['multiple']"
								v-model="form[item.prop]"
							></chooseQuestionBank>
						</slot>
					</el-form-item>
				</template>
				<slot name="append"></slot>
			</el-form>
		</div>
		<template #footer>
			<slot name="footer">
				<el-button type="primary" @click="onConfirm" :loading="saving" v-if="mode === 'DESIGN'"
					>确定
				</el-button>
			</slot>
		</template>
	</el-dialog>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

import chooseOffic from '../chooseOffic/index'
import choosePic from '../choosePic/index'
import chooseType from '../chooseType/index'
import chooseDatabase from '../chooseDatabase/index'
import chooseTopic from '../chooseTopic/index'
import chooseQuestionBank from '../chooseQuestionBank/index'
import chooseSubject from '../chooseSubject/index'
const emit = defineEmits(['submit', 'selectChange'])
const props = defineProps({
	/**
	 * 是否加载数据。修改、查看详情时 需要加载数据
	 */
	loading: {
		type: Boolean,
		default: false
	},
	list: {
		type: Array,
		default: []
	},
	form: {
		type: Object,
		default: {}
	},
	saving: {
		type: Boolean,
		default: false
	},
	mode: {
		type: String,
		default: 'DESIGN'
	}
})
const formRef = ref()
const onConfirm = () => {
	formRef.value.validate((valid) => {
		if (valid) {
			emit('submit', props.form)
		}
	})
}
const selectChange = (event, item) => {
	emit('selectChange', event, item)
}
onMounted(() => {})

onUnmounted(() => {})
</script>

<style scoped>
.el-form {
	display: flex;
	flex-wrap: wrap;
}
.el-form-item {
	width: 100%;
}
</style>
