import http from '@/http/axios_init2'

// 公职类型列表
export function getpoList(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/poList',
		data
	})
}

//新增公职类型
export function addpoList(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/addPo',
		data
	})
}

//编辑公职类型
export function editpoList(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/editPo',
		data
	})
}

//删除公职类型
export function delpoList(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/delPo',
		data
	})
}

//活动列表
export function getActivityList(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/activityList',
		data
	})
}
