<template>
	<el-upload
		ref="upload"
		v-model:file-list="fileList"
		:action="isLocal == true ? localURL : uploadURL"
		:multiple="is_multi"
		:limit="limit"
		:on-success="success"
		:on-remove="handleRemove"
		:on-exceed="handleExceed"
		:before-upload="beforeUpload"
	>
		<slot>
			<el-button type="default" icon="Upload">上传文件</el-button>
		</slot>
	</el-upload>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue'
import { ElMessage } from 'element-plus'
import cfg from '@/config/config.js'
const uploadURL = cfg.HOST2 + '/XuelianAdmin/uploadOss'
const localURL = 'https://dcedu.kmwl.top/common/upload/uploads'
const model = defineModel()
const props = defineProps({
	// 是否多文件上传
	multiple: {
		type: Boolean,
		default: true
	},
	//是否上传本地
	isLocal: {
		type: Boolean,
		default: false
	}
})
const limit = ref(1)
const is_multi = ref(true)
const fileList = ref([])
const upload = ref()
const fileType2 = ref('')
const emit = defineEmits(['change'])
const stopUpload = ref(false)
onMounted(() => {
	if (model.value.length != 0) {
		if (is_multi.value == true) {
			fileList.value.push({
				name: model.value[0].path
			})
		} else {
			fileList.value = [
				{
					name: model.value[0].path
				}
			]
		}
	}
})

watch(
	() => props.multiple,
	(newVal) => {
		if (newVal) {
			limit.value = 999
			is_multi.value = true
		} else {
			limit.value = 1
			is_multi.value = false
		}
	},
	{
		immediate: true
	}
)

const beforeUpload = (file) => {
	console.log('filefilefilefilefilefile', file)
	let allowedFileTypes = []
	if (props.isLocal) {
		allowedFileTypes = [
			'application/vnd.ms-excel.sheet.macroEnabled.12',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		]
	} else {
		allowedFileTypes = [
			'image/jpeg', // JPEG 图像文件
			'image/png', // PNG 图像文件
			'video/mp4', // MP4 视频文件
			'video/avi', // AVI 视频文件 (请确认正确的MIME类型)
			'application/msword', // Microsoft Word 文档
			'application/vnd.ms-excel', // Microsoft Excel 表格
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/pdf',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/vnd.ms-excel.sheet.macroEnabled.12'
		]
	}

	// 获取文件的类型
	const fileType = file.type
	fileType2.value = file.type
	if (!allowedFileTypes.includes(fileType)) {
		stopUpload.value = true
		ElMessage({
			type: 'error',
			message: '上传格式错误'
		})
		return false // 阻止文件上传
	}
}

const success = (e) => {
	if (is_multi.value == true && !props.isLocal) {
		model.value.push({
			path: e.data.osspath,
			fileType: fileType2.value
		})
	} else if (is_multi.value == false && !props.isLocal) {
		model.value.length = 0
		model.value.push({
			path: e.data.osspath,
			fileType: fileType2.value
		})
	} else if (is_multi.value == false && props.isLocal) {
		model.value.length = 0
		model.value.push({
			path: e.data.imagePath,
			fileType: fileType2.value
		})
	}
}
/**
 * 移除文件，删除
 * @param file
 */
function handleRemove(file) {
	if (is_multi.value == true) {
		model.value = model.value.filter((t) => t.path != file.response.data.osspath)
	} else {
		model.value.length = 0
	}
}

function handleExceed(files) {
	if (!props.multiple && !stopUpload.value) {
		upload.value.clearFiles()
		upload.value.handleStart(files[0])
		upload.value.submit()
	} else {
		upload.value.handleStart(files[0])
		upload.value.submit()
	}
}
</script>

<style scoped>
:deep(.el-upload--picture-card) {
	--el-upload-picture-card-size: 120px;
}

:deep(.el-upload-list--picture-card) {
	--el-upload-list-picture-card-size: 120px;
}
</style>
