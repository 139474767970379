<template>
	<div class="box query">
		<el-form :model="query" ref="queryRef" :inline="true">
			<el-form-item>
				<el-input v-model="query.title" placeholder="题目标题" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSearch" icon="search">查询</el-button>
			</el-form-item>
		</el-form>
	</div>
	<el-table
		ref="multipleTable"
		v-loading="loading"
		:data="tableData.data"
		row-key="id"
		border
		stripe
		style="width: 100%; height: 200px"
		:header-cell-style="{ background: 'var(--el-fill-color-light)' }"
		@selection-change="onSelectionChange"
	>
		<el-table-column
			label=""
			reserve-selection
			type="selection"
			width="55"
			fixed="left"
		></el-table-column>
		<dc-table-column v-for="item in tableProps" :key="item" :item="item"></dc-table-column>
	</el-table>
	<el-pagination
		v-if="total > 0"
		layout="prev, pager, next"
		:default-page-size="20"
		:total="total"
		@change="paginationChange"
	/>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { useBaseMixins } from '@/utils/base_mixins.js'
import { getProfile } from '@/api/dataBase.js'
const model = defineModel({ type: Array, default: [] })
const { query, loading, tableData, selectionNodes } = useBaseMixins()
const total = ref(0)
const page = ref(1)
const multipleTable = ref(null)
const allowMult = ref(false)
const tableProps = ref([
	{
		label: '标题',
		prop: 'title',
		sortable: true,
		tooltip: true
		// fixed: 'left'
	},

	{
		label: '地区',
		prop: 'rename'
	},
	{
		label: '公职类型',
		prop: 'po_name'
	}
])

/**
 * 选择
 * @param nodes
 */
function onSelectionChange(nodes) {
	console.log('888888888888888', nodes)
	selectionNodes.value = nodes
	model.value = nodes
}
const allData = ref([])
const getData = async (e) => {
	loading.value = true
	const res = await getProfile(e)
	if (res.code === 1) {
		tableData.value.data = res.data.data
		loading.value = false
		total.value = res.data.total
		// console.log('model', model.value)
		allData.value = JSON.parse(JSON.stringify(model.value))
		console.log('dddd', allData.value)
		if (model.value.length > 0 && !allowMult.value) {
			model.value.forEach((item) => {
				const row = tableData.value.data.find((f) => f.id == item)
				if (row != undefined) {
					console.log('row', item, tableData.value.data, row)
					multipleTable.value.toggleRowSelection(row, true)
				}
			})
		}
	}
}
const getData2 = async (e) => {
	loading.value = true
	const res = await getProfile(e)
	if (res.code === 1) {
		tableData.value.data = res.data.data
		loading.value = false
		total.value = res.data.total
		if (allData.value.length > 0 && !allowMult.value) {
			allData.value.forEach((item) => {
				const row = tableData.value.data.find((f) => f.id == item)
				if (row != undefined) {
					console.log('row', item, tableData.value.data, row)
					multipleTable.value.toggleRowSelection(row, true)
				}
			})
		}
	}
}
// 页数修改
const paginationChange = (val) => {
	page.value = val
	getData2({ page: page.value })
}
const onSearch = () => {
	query.value.page = 1
	getData(query.value)
	allowMult.value = true
}
onMounted(() => {
	allowMult.value = false
	getData()
})

onUnmounted(() => {})
</script>

<style scoped>
.query {
	margin-bottom: 20px;
}
</style>
