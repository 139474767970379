<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2024-07-11 21:05:56
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-08-23 11:36:19
-->
<template>
	<el-container class="skin">
		<el-header>
			<div class="bar">
				<div class="logo">
					<img alt="Vue logo" src="@/assets/logo.png" />
					鼎成教育
				</div>

				<div class="menus">
					<ul>
						<li
							v-for="item in topNaves"
							:key="item"
							:class="{ active: item.is_active }"
							@click="onMenuClick(item)"
						>
							{{ item.meta.title }}
						</li>
					</ul>
				</div>

				<div class="profile">
					<el-dropdown>
						<span class="user">
							<el-avatar
								src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
							></el-avatar>
							<label>{{ userInfo.a_username }}</label>
						</span>
						<template #dropdown>
							<el-dropdown-menu>
								<!-- <el-dropdown-item>
									<el-link href="/profile" :underline="false">个人中心</el-link>
								</el-dropdown-item> -->
								<el-dropdown-item icon="el-icon-switch-button" @click="onLogout"
									>退出</el-dropdown-item
								>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
				</div>
			</div>
		</el-header>
		<el-container>
			<el-aside
				width="200px"
				v-if="currentRootItem && !currentRootItem.hideSide && routes && routes.length"
			>
				<el-scrollbar>
					<side-bar :routes="routes" :activeIndex="activeIndex"></side-bar>
				</el-scrollbar>
			</el-aside>
			<el-main class="fex-column">
				<el-breadcrumb separator="/">
					<!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
					<el-breadcrumb-item v-for="item in breadList" :key="item">{{
						item.meta.title
					}}</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="main-content">
					<router-view></router-view>
				</div>
			</el-main>
		</el-container>
	</el-container>
	<!-- 公告展示 -->
	<el-dialog v-model="msgShow" :title="activeMsg.title" width="500" @close="cancelOperate">
		<el-descriptions :column="1">
			<el-descriptions-item label="标题">{{ activeMsg.title }}</el-descriptions-item>
			<el-descriptions-item label="类型">{{ activeMsg.types }}</el-descriptions-item>
			<el-descriptions-item label="时间">{{
				activeMsg.create_time ? activeMsg.create_time : activeMsg.send_time
			}}</el-descriptions-item>
			<!-- <el-descriptions-item label="标题">{{ activeMsg.title }}</el-descriptions-item> -->
		</el-descriptions>
		<template #footer>
			<div class="dialog-footer">
				<el-button @click="viewDetail">查看公告</el-button>
				<!-- <el-button type="primary" @click="addOperateList"> 加入运营 </el-button> -->
			</div>
		</template>
	</el-dialog>
	<!-- 公告详情 -->
	<el-dialog v-model="isShowDetail" title="咨询详情" width="1000" @close="cancelOperate">
		<div v-html="detailItem.content" class="content"></div>
		<template #footer>
			<div class="dialog-footer">
				<el-button @click="cancelOperate">取 消</el-button>
				<!-- <el-button type="primary" @click="addOperateList"> 运 营 </el-button> -->
			</div>
		</template>
	</el-dialog>
</template>

<script>
import SideBar from '@/layout/sidebar'
import menus from '@/config/menus.js'
// import defaultMenu from '@/config/defaultMenu'

import config from '@/config/config'
// import { useWebSocket } from '@vueuse/core'
// import {
// 	bindSuccess,
// 	queryMsgList,
// 	queryMsgRead,
// 	queryNoticeDetail,
// 	queryOperatingHistory
// } from '@/api/socket'
// import { addOperate } from '@/api/home'
// import { ElNotification, ElMessage } from 'element-plus'
import { apiMenuList } from '@/api/login'
export default {
	components: { SideBar },
	data() {
		return {
			// 菜单列表
			topNaves: [],
			routes: [], //左侧菜单路由列表
			currentRootItem: null,
			activeIndex: '',
			//breadcrumb列表
			breadList: [],
			userInfo: localStorage.getItem(config.USERINFO),
			msgNum: 0,
			warnInfo: '',
			msgList: [],
			activeMsg: {},
			msgShow: false,
			page: 1,
			isShowDetail: false,
			detailItem: {},
			apiMenus: []
		}
	},
	watch: {
		/**
		 * 监听路由变化
		 */
		$route() {
			this.getBreadcrumb()
		}
	},

	beforeRouteEnter(to, form, next) {
		let flag = form.name == 'login' ? 1 : 2
		localStorage.setItem('fristIn', flag)
		next()
	},
	created() {
		console.log(this.$route.path)
		this.current_path = this.$route.path
		// defaultMenu.forEach((el) => {
		// 	this.topNaves.push(el)
		// })
		this.queryMenus().then(() => {
			// 判断父级
			this.apiMenus.forEach((item) => {
				menus.forEach((el) => {
					if (item.name == el.meta.title) {
						this.topNaves.push(el)
					}
				})
			})
			this.hierarchyJudgment().then(() => {
				// defaultMenu.forEach((el) => {
				// 	menus.unshift(el)
				// })
				menus.forEach((item) => {
					item.is_active = item.urls.includes(this.current_path)
					if (item.is_active) {
						this.routes = item.children
						this.currentRootItem = item
					}
					this.menuRecursion(item, null, item)
				})
			})
		})

		if (localStorage.getItem('fristIn') == 1) {
			this.$router.replace(menus[0].redirect)
		}

		// menus.forEach((item) => {
		// 	//如果有权限则添加至头部菜单
		// 	if (item.ignore || item.allow) {

		// 	}
		// })
	},

	mounted() {
		this.getBreadcrumb()
		// this.contentSOCK()
		// this.queryMessageList()
	},

	methods: {
		hierarchyJudgment() {
			return new Promise((resolve, reject) => {
				// 判断子集
				menus.forEach((el) => {
					if (el.children && el.children.length) {
						// 第一层
						el.children.forEach((val) => {
							// 第二层
							if (val.children && val.children.length) {
								val.children.forEach((item) => {
									if (this.routeExistsRecursively(this.apiMenus, item)) {
										item.hideSide = false
									} else {
										item.hideSide = true
									}
								})
								// 判断父级状态
								if (
									val.children.every((item) => {
										return item.hideSide == true
									})
								) {
									val.hideSide = true
								}
							} else {
								if (this.routeExistsRecursively(this.apiMenus, val)) {
									val.hideSide = false
								} else {
									val.hideSide = true
								}
							}
						})
						// 判断父级状态
						if (
							el.children.every((val) => {
								return val.hideSide == true
							})
						) {
							el.hideSide = true
						}
					} else {
						if (this.routeExistsRecursively(this.apiMenus, el)) {
							el.hideSide = false
						} else {
							el.hideSide = true
						}
					}
				})
				resolve()
			})
		},

		// 判断路由是否有权限
		routeExistsRecursively(routes, path) {
			// let flag = false

			for (const el of routes) {
				// 如果当前路由有子路由，则递归检查
				if (el.children && el.children.length) {
					// 递归调用 checkNestedRoutes，并检查返回值
					if (this.routeExistsRecursively(el.children, path)) {
						return true // 找到匹配项，立即返回 true
					}
				} else {
					// 否则，直接比较 name 和 title
					if (el.name === path.meta.title) {
						return true // 找到匹配项，立即返回 true
					}
				}
			}
			return false
		},
		queryMenus() {
			return new Promise((resolve, reject) => {
				apiMenuList()
					.then((res) => {
						this.apiMenus = res.data
						resolve()
					})
					.catch((error) => {
						reject(error)
					})
			})
		},
		menuRecursion(item, father, root) {
			if (item.path && item.path != '/') {
				//检查权限 如果有权限则标识
				item.allow = true
				if (father) {
					father.allow = true
				}
				if (!root.redirect) {
					root.redirect = item.path
					root.activeIndex = item.id
				}
				if (item.path == this.current_path) {
					this.activeIndex = item.id
				}
			} else {
				if (item.children && item.children.length > 0) {
					item.children.forEach((child) => {
						this.menuRecursion(child, item, root)
					})
				}
			}

			if (item.allow) {
				if (father) {
					father.allow = true
				}
			}
		},

		/**
		 * 点击菜单
		 * @param {Object} item
		 */
		onMenuClick(item) {
			menus.forEach((item) => {
				item.is_active = false
			})

			item.is_active = true
			this.activeIndex = item.activeIndex
			this.$router.push({ path: item.redirect })
			this.routes = item.children
			this.currentRootItem = item
		},

		/**
		 * 获取breadcrumb
		 */
		getBreadcrumb() {
			if (this.$route.matched) {
				this.breadList = this.$route.matched
			} else {
				this.breadList = []
			}
		},

		/**
		 *
		 */
		onLogout() {
			//
			sessionStorage.removeItem(this.$cfg.TOKEN)
			// localStorage.removeItem(config.USERINFO)
			this.$router.push({ path: '/' })
		},

		// 查看公告
		viewDetail() {
			this.isShowDetail = true
			queryNoticeDetail({
				id: this.activeMsg.notice_id
			}).then((res) => {
				this.detailItem = res.data.info
			})
		},
		cancelOperate() {
			this.isShowDetail = false
			this.msgShow = false
		},
		// 连接websocket
		// contentSOCK() {
		// 	/**
		// 	 * status 是连接的状态值
		// 	 * data 是 socket 推送过来的消息
		// 	 * send 是一个方法， 用来发送消息给后台
		// 	 * close 是一个方法，关闭 socket 连接
		// 	 * open 如果当前的websocket是活跃的，将会关闭它重新打开一个新的
		// 	 */
		// 	let that = this
		// 	const { status, data, send, open, close } = useWebSocket('ws://8.140.205.132:8282', {
		// 		// 连接成功的回调
		// 		onConnected: function (ws) {},
		// 		// 断开的情况
		// 		onDisconnected: function (ws, event) {
		// 			console.log('onDisconnected')
		// 		},
		// 		onError: function (ws, event) {
		// 			console.log('onError')
		// 		},
		// 		onMessage: function (ws, event) {
		// 			switch (JSON.parse(event.data).type) {
		// 				case 'init':
		// 					bindSuccess({
		// 						client_id: JSON.parse(event.data).client_id,
		// 						a_id: JSON.parse(localStorage.getItem(config.USERINFO)).a_id
		// 					})
		// 					break
		// 				case 'send':
		// 					const info = JSON.parse(event.data).data
		// 					that.warnInfo = info
		// 					ElNotification({
		// 						title: '您有一条新消息',
		// 						message: info.title,
		// 						type: 'warning',
		// 						duration: 10000
		// 					})
		// 					that.msgList.unshift(JSON.parse(event.data).data)
		// 					that.msgNum++
		// 					break
		// 			}
		// 		},
		// 		heartbeat: false,
		// 		autoReconnect: {
		// 			retries: 100,
		// 			delay: 5000,
		// 			onFailed() {
		// 				alert('多次重新连接失败，请检查网络后重新刷新')
		// 			}
		// 		},
		// 		autoClose: true
		// 	})
		// },
		// 获取消息列表数据
		queryMessageList() {
			queryMsgList({
				a_id: JSON.parse(localStorage.getItem(config.USERINFO)).a_id,
				page: this.page
			}).then((res) => {
				this.msgList = [...this.msgList, ...res.data.data]
				this.msgNum = res.data.total
			})
		},
		// 消息 已读状态
		messageReaded(val, index) {
			queryMsgRead({
				ids: val.id
			}).then((res) => {
				this.msgList[index].is_yue = 1
				this.msgNum--
				this.msgShow = true
				this.activeMsg = val
			})
		},
		load() {
			this.page++
			this.queryMessageList()
		}
	}
}
</script>

<style scoped>
.msgAll {
	width: 500px;
	height: 500px;
	overflow-y: auto;
	border-radius: 20px;
	.msgItem {
		display: flex;
		padding: 10px;
		justify-content: space-between;
		align-items: center;
		.unread {
			color: #000;
		}
		.read {
			color: #747474;
		}
		.itemDe {
			width: 300px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}
</style>
