<template>
	<el-upload
		ref="upload"
		v-model:file-list="fileList"
		:action="uploadURL"
		:multiple="is_multi"
		:limit="limit"
		accept="image/*"
		list-type="picture-card"
		:on-success="success"
		:on-preview="handlePictureCardPreview"
		:on-remove="handleRemove"
		:on-exceed="handleExceed"
		:before-upload="beforeUpload"
	>
		<el-icon><Plus /></el-icon>
	</el-upload>
	<!-- 图片预览 -->
	<el-image-viewer
		v-if="showImagePreview"
		:zoom-rate="1.2"
		@close="closePreview"
		:url-list="previewList"
	/>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue'
import { ElMessage } from 'element-plus'
import cfg from '@/config/config.js'
const uploadURL = cfg.HOST2 + '/XuelianAdmin/uploadOss'
const showImagePreview = ref(false)
const previewList = ref([])
const model = defineModel({ type: Array, default: [] })

const props = defineProps({
	// 是否多文件上传
	multiple: {
		type: Boolean,
		default: false
	}
})
const limit = ref(1)
const is_multi = ref(true)
const fileList = ref([])
const upload = ref()
const emit = defineEmits(['change'])
const stopUpload = ref(false)
onMounted(() => {})
watch(
	() => props.multiple,
	(newVal) => {
		if (newVal) {
			limit.value = 999
			is_multi.value = true
		} else {
			limit.value = 1
			is_multi.value = false
		}
	},
	{
		immediate: true
	}
)
watch(
	() => model.value,
	(newVal, oldVal) => {
		if (model.value.length > 0) {
			if (newVal && is_multi.value == true) {
				fileList.value.push({
					url: newVal[0].path
				})
				previewList.value.push(newVal[0].path)
			} else {
				fileList.value = [
					{
						url: newVal[0].path
					}
				]
				previewList.value = [newVal[0].path]
			}
		}
	},
	{
		immediate: true
	}
)
const beforeUpload = (file) => {
	const allowedFileTypes = [
		'image/jpeg', // JPEG 图像文件
		'image/png' // PNG 图像文件
	]
	// 获取文件的类型
	const fileType = file.type
	if (!allowedFileTypes.includes(fileType)) {
		stopUpload.value = true
		ElMessage({
			type: 'error',
			message: '上传格式错误'
		})
		return false // 阻止文件上传
	}
}
const success = (e) => {
	console.log('55555555555555', is_multi.value)
	if (is_multi.value == true) {
		model.value.push({
			path: e.data.osspath
		})
	} else {
		console.log('1111111111111111')
		model.value.length = 0
		model.value.push({
			path: e.data.osspath
		})
	}
}
/**
 * 移除文件，删除
 * @param file
 */
function handleRemove(file) {
	if (is_multi.value == true) {
		console.log('file', file, model.value, tableData.value.data)
		tableData.value.data = tableData.value.data.filter((t) => t.url != file.response.data.osspath)
	} else {
		model.value.length = 0
		previewList.value = []
	}
}

function handleExceed(files) {
	if (!props.multiple && !stopUpload.value) {
		upload.value.clearFiles()
		upload.value.handleStart(files[0])
		upload.value.submit()
	} else {
		upload.value.handleStart(files[0])
		upload.value.submit()
	}
}
/**
 * 预览
 * @param file
 */
function handlePictureCardPreview(file) {
	console.log(file)
	previewList.value = []
	if (is_multi.value == true && 'response' in file) {
		previewList.value.push(file.response.data.osspath)
	} else {
		previewList.value.push(file.url)
	}
	showImagePreview.value = true
}
const closePreview = () => {
	showImagePreview.value = false
}
</script>

<style scoped>
:deep(.el-upload--picture-card) {
	--el-upload-picture-card-size: 100px;
	display: inline-flex !important;
}
:deep(.el-upload-list--picture-card) {
	--el-upload-list-picture-card-size: 100px;
}

:deep(.el-upload-list--picture-card .el-progress) {
	transform: translate(-50%, -50%) scale(0.8);
}
</style>
