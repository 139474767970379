<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2024-07-12 11:53:30
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-07-28 20:40:53
-->
<template>
	
	<el-sub-menu :index="father.id" v-if="menus && menus.length">
		<template #title>
			<el-icon v-if="father.icon != null && father.icon != ''">
				<component :is="father.icon" />
			</el-icon>
			{{ father.meta.title }}
		</template>

		<sub-menu
			v-for="item in father.children"
			:key="item"
			:father="item"
			:menus="item.children"
		></sub-menu>
	</el-sub-menu>

	<el-menu-item
		v-if="!father.hideSide && (!menus || menus.length == 0) "
		:index="father.id"
		:route="{ path: father.path ? `${father.path}` : '' }"
	>
		<div class="item-content">
			<el-icon v-if="father.icon != null && father.icon != ''">
				<component :is="father.icon" />
			</el-icon>
			{{ father.meta.title }}
		</div>
	</el-menu-item>
</template>

<script>
export default {
	name: 'SubMenu',
	data() {
		return {}
	},
	props: ['father', 'menus']
}
</script>

<style>
.skin .el-aside {
	--height: 36px;
	--radius: 4px;
	--padding: 12px;
}

.skin .el-aside .el-sub-menu {
	margin: 5px 0;
}

.skin .el-menu-item {
	height: var(--height);
	line-height: var(--height);
	margin: 5px 0;
}
.skin .el-menu-item:hover {
	background: transparent;
}
.skin .el-aside .el-menu-item .item-content:hover {
	background: var(--aside-item-hover-bg-color);
}
/* 激活状态 */
.skin .el-aside .el-menu-item.is-active .item-content {
	background: var(--aside-item-hover-bg-color);
	color: #172b4d;
}

.skin .el-aside .el-sub-menu__title {
	height: var(--height);
	line-height: var(--height);
	margin: 0 12px;
	padding-left: var(--padding);
	border-radius: var(--radius);
}
.skin .el-aside .el-sub-menu__title:hover {
	background: var(--aside-item-hover-bg-color);
}
.skin .el-sub-menu.is-active .el-sub-menu__title {
	background: var(--aside-item-hover-bg-color);
}
.skin .el-aside .el-menu-item .item-content {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	padding: 0 var(--padding);
	border-radius: var(--radius);
}
</style>
