import http from '@/http/axios_init2'

//新增资料库
export function addProfile(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/addProfile',
		data
	})
}
//资料库列表
export function getProfile(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/listProfile',
		data
	})
}

//资料库编辑
export function editProfile(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/editProfile',
		data
	})
}

//资料库删除
export function edlProfile(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/delProfile',
		data
	})
}
