<template>
	<div v-for="(item, index) in picList" :key="index" class="pic-choose" @click="chooseOne(item)">
		<img :src="item" alt="" :class="[item === model ? 'selected' : '']" />
	</div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

const model = defineModel({ type: String, default: '' })
const props = defineProps({
	picList: {
		type: Array,
		default: []
	}
})
const chooseOne = (e) => {
	model.value = e
}
onMounted(() => {})

onUnmounted(() => {})
</script>

<style scoped>
:deep(.el-icon) {
	position: absolute;
	top: 0;
	right: 10px;
}
.pic-choose {
	position: relative;
	cursor: pointer;
}

.selected {
	transform: scale(1.2);
	transition: transform 0.3s ease;
}
</style>
