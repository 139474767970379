/*
 * @Description: 网络请求
 * @Author: 琢磨先生
 * @Date: 2024-07-11 20:58:10
 * @LastEditors: 鱿鱼须 1519964028@qq.com
 * @LastEditTime: 2024-08-13 11:03:41
 */
import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from '@/plugins/router'
import cfg from '@/config/config.js'

let config = {
	baseURL: cfg.HOST,
	timeout: 600000 //
}
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['Access-Control-Allow-Origin'] = '*'
const _axios = axios.create(config)

//请求开始时链接
_axios.interceptors.request.use(
	(config) => {
		//平台应用key
		config.headers.app = cfg.APP_KEY
		config.headers.platform = cfg.APP_PLATFORM

		// 获取token
		const token = sessionStorage.getItem(cfg.TOKEN)
		if (token) {
			config.headers.Authorization = token
		}

		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

_axios.interceptors.response.use(
	(response) => {
		if (response.config.responseType == 'blob') {
			return response.data
		}
		// console.log(response.config.url, response.data);
		//未登录或已过期
		if (response.data.code == 9002) {
			ElMessage.error(response.data.msg)
			router.replace({
				name: 'login'
			})
		} else if (response.data.code == 9001) {
			ElMessage.error(response.data.msg)
			router.replace({
				name: 'login'
			})
		} else if (response.data.code != 200) {
			// ElMessage.error(response.data.msg);
		}

		return response.data
	},
	(error) => {
		// var message = `网络请求错误，状态码：${error.response.status}`;
		// switch (error.response.status) {
		//   case 403:
		//     message = "没有权限，拒绝访问";
		//     break;
		// }
		// ElMessage.error(message);
		return Promise.reject(error)
	}
)

export default _axios
