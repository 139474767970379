import http from '@/http/axios_init2'

//素材列表
export function getmList(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/mList',
		data
	})
}

//新增素材
export function addMaterial(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/addMaterial',
		data
	})
}

//删除素材
export function delMaterial(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/delMaterial',
		data
	})
}

//编辑素材
export function editMaterial(data) {
	return http({
		method: 'post',
		url: 'XuelianAdmin/editMaterial',
		data
	})
}
