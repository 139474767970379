<template>
	<div class="box query">
		<el-form :model="query" ref="queryRef" :inline="true">
			<el-form-item>
				<el-input
					v-model="query.title"
					style="width: 150px"
					placeholder="题目标题"
					clearable
				></el-input>
			</el-form-item>
			<el-form-item>
				<el-select
					v-model="query.po_id"
					placeholder="公职类型"
					style="width: 150px"
					clearable
					filterable
				>
					<el-option
						v-for="item in officList"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					/>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-cascader
					:options="typeList"
					clearable
					filterable
					:props="props"
					v-model="query.type_ids"
					placeholder="题库类型"
					@clear="clearCascader"
					style="width: 150px"
				></el-cascader>
			</el-form-item>
			<el-form-item>
				<el-select
					v-model="query.flag"
					placeholder="题目类型"
					style="width: 150px"
					clearable
					filterable
				>
					<el-option
						v-for="item in timuType"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					/>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSearch" icon="search">查询</el-button>
			</el-form-item>
		</el-form>
	</div>
	<el-table
		ref="multipleTable"
		v-loading="loading"
		:data="tableData.data"
		row-key="id"
		border
		stripe
		style="width: 100%; height: 350px"
		:header-cell-style="{ background: 'var(--el-fill-color-light)' }"
		@selection-change="onSelectionChange"
	>
		<el-table-column reserve-selection type="selection" width="55" fixed="left"></el-table-column>
		<dc-table-column v-for="item in tableProps" :key="item" :item="item"></dc-table-column>
	</el-table>
	<el-pagination
		v-if="total > 0"
		layout="prev, pager, next"
		:default-page-size="20"
		:total="total"
		@change="paginationChange"
	/>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { useBaseMixins } from '@/utils/base_mixins.js'
import { getSource } from '@/api/questionBank'
const model = defineModel({ type: Array, default: [] })
const { query, loading, tableData, selectionNodes } = useBaseMixins()
import { getpoList } from '@/api/officType'
import { getlistType } from '@/api/topicType.js'
const total = ref(0)
const page = ref(1)
const multipleTable = ref(null)
const allowMult = ref(false)
const props = { label: 'name', value: 'id', children: 'child', checkStrictly: true }
const tableProps = ref([
	{
		label: '标题',
		prop: 'title',
		type: 'html',
		width: 300

		// fixed: 'left'
	},
	{
		label: '公职类型',
		prop: 'po_name'
	},
	{
		label: '题库类型',
		prop: 'type_name'
	},
	{
		label: '题目类型',
		prop: 'flag'
	}
])
const timuType = [
	{
		label: '单选题',
		value: '单选题'
	},
	{
		label: '多选题',
		value: '多选题'
	},
	{
		label: '判断题',
		value: '判断题'
	}
]
//公职类型
const officList = ref([])
const typeList = ref([])
const getpoAllList = async () => {
	const res = await getpoList({
		type: 'xuelian',
		flag: 'two'
	})
	res.data.forEach((item) => {
		officList.value.push({
			label: item.name,
			value: item.id
		})
	})
}
const clearCascader = () => {
	query.value.type_id = ''
}
const getTypeList = async () => {
	const res = await getlistType()
	typeList.value = res.data
	console.log('typeList.value', typeList.value)
}
/**
 * 选择
 * @param nodes
 */
function onSelectionChange(nodes) {
	selectionNodes.value = nodes
	model.value = nodes
}
const allData = ref([])
const getData = async (e) => {
	loading.value = true
	const res = await getSource(e)
	if (res.code === 1) {
		tableData.value.data = res.data.data
		loading.value = false
		total.value = res.data.total
		// console.log('model', model.value)
		allData.value = JSON.parse(JSON.stringify(model.value))
		console.log('dddd', allData.value)
		if (model.value.length > 0 && !allowMult.value) {
			model.value.forEach((item) => {
				const row = tableData.value.data.find((f) => f.id == item)
				if (row != undefined) {
					console.log('row', item, tableData.value.data, row)
					multipleTable.value.toggleRowSelection(row, true)
				}
			})
		}
	}
}
const getData2 = async (e) => {
	loading.value = true
	const res = await getSource(e)
	if (res.code === 1) {
		tableData.value.data = res.data.data
		loading.value = false
		total.value = res.data.total
		if (allData.value.length > 0 && !allowMult.value) {
			allData.value.forEach((item) => {
				const row = tableData.value.data.find((f) => f.id == item)
				if (row != undefined) {
					console.log('row', item, tableData.value.data, row)
					multipleTable.value.toggleRowSelection(row, true)
				}
			})
		}
	}
}
// 页数修改
const paginationChange = (val) => {
	page.value = val
	allowMult.value = false
	getData2({ page: page.value })
}
const onSearch = () => {
	query.value.page = 1
	if (query.value.type_ids && query.value.type_ids != '') {
		query.value.type_id = query.value.type_ids.toString()
	}
	getData(query.value)
	allowMult.value = true
}
onMounted(() => {
	allowMult.value = false
	getData({ page: page.value })
	getpoAllList()
	getTypeList()
})

onUnmounted(() => {})
</script>

<style scoped>
.query {
	margin-bottom: 20px;
}
</style>
