/*
 * @Author: 鱿鱼须 1519964028@qq.com
 * @Date: 2024-07-25 17:20:01
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-07-27 00:22:39
 * @FilePath: \dc.amin\src\api\login
 */
import http from '@/http/axios_init'

// 登录
export function apiLogin(data) {
	return http({
		method: 'POST',
		url: 'api/admin/v1/login',
		data
	})
}

// 获取密码盐
export function apiSalt(phone) {
	return http({
		method: 'POST',
		url: `api/admin/v1/login/get_salt?mobile_phone=${phone}`
	})
}
// 获取菜单
export function apiMenuList(phone) {
	return http({
		method: 'POST',
		url: "api/frontier/get_menus"
	})
}
